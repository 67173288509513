import type { ReactNode } from "react";

import { StandardIconType } from "@wind/common-interfaces/icons";

import {
  AccomplishmentsIcon,
  ActivityIcon,
  AiIcon,
  AlertIcon,
  AnalyticsIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowToDownLine,
  ArrowToUpLine,
  ArrowUpIcon,
  BellOffIcon,
  BellRingIcon,
  BotIcon,
  CalendarIcon,
  CardIcon,
  CaretUpFilledIcon,
  ChannelIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronDownPixelIcon,
  ChevronRightIcon,
  ChooseStatsIcon,
  ClockIcon,
  CodeIcon,
  ColumnsIcon,
  CompanyIcon,
  ComparisonIcon,
  ConnectIcon,
  ConnectionsIcon,
  CopyIcon,
  CostIcon,
  DebugIcon,
  DeepDiveIcon,
  DeliveryIcon,
  DeviceDesktopAnalyticsIcon,
  DisconnectIcon,
  DocumentIcon,
  DotIcon,
  EditIcon,
  EmailIcon,
  EmployeeIcon,
  EmployeesIcon,
  ErrorIcon,
  ExternalLinkIcon,
  EyeIcon,
  FeatureIndeterminateIcon,
  FeatureOffIcon,
  FeatureOnIcon,
  FeaturesIcon,
  FeedbackGivenIcon,
  FeedbackReceivedIcon,
  FilterIcon,
  FlagIcon,
  FullScreenIcon,
  GlobeIcon,
  HamburgerIcon,
  HappyIcon,
  HealthIcon,
  HeroNumberIcon,
  HighlightsIcon,
  HomeGridIcon,
  HomeIcon,
  ImpactIcon,
  InfoCircleIcon,
  IntegrationsIcon,
  KeyboardDoubleArrowRight,
  KeyboardSingleArrowRight,
  KeyIcon,
  LeaderboardIcon,
  LightBulbIcon,
  LightningBoltIcon,
  LinkedInIcon,
  LinkIcon,
  LoadingIcon,
  LockIcon,
  LogoutIcon,
  MailboxIcon,
  MappingIcon,
  MedalIcon,
  MeetingsIcon,
  MergeIcon,
  NewActivityIcon,
  NewTabIcon,
  NotificationsIcon,
  OrgChartIcon,
  OrgsIcon,
  PaintBrushIcon,
  PanelLeftCloseIcon,
  PanelLeftOpenIcon,
  PencilBoltIcon,
  PersonCheckIcon,
  PersonIcon,
  PersonMultipleCheckIcon,
  PlayCircleIcon,
  PlusCircleIcon,
  PlusIcon,
  RecordingIcon,
  RefreshIcon,
  SadIcon,
  SavedViewIcon,
  SearchIcon,
  SendIcon,
  SettingsIcon,
  SmsIcon,
  SpinnerIcon,
  StarFilledIcon,
  StarIcon,
  StatsIcon,
  SummaryIcon,
  SuperpowersIcon,
  SwitchIcon,
  SyncIntegrationIcon,
  TableIcon,
  TagIcon,
  TeamIcon,
  TelescopeIcon,
  TestIcon,
  ThreeDotsVerticalIcon,
  ThumbsUpIcon,
  TimeSpentIcon,
  TrashIcon,
  TrendingDownIcon,
  TrendingUpIcon,
  TwitterIcon,
  UserCircleIcon,
  UserIcon,
  UserPlusIcon,
  WrenchIcon,
  XIcon,
  YouTubeIcon,
} from "~/utils/icons.js";
import WindyStickerIcon from "../components/WindyStickerIcon.js";

export const getStandardIcon = (iconType: StandardIconType, iconProps: any = {}): ReactNode => {
  switch (iconType) {
    case StandardIconType.ACCOMPLISHMENTS:
      return <AccomplishmentsIcon {...iconProps} />;
    case StandardIconType.ACTIVITY:
      return <ActivityIcon {...iconProps} />;
    case StandardIconType.AI:
      return <AiIcon {...iconProps} />;
    case StandardIconType.ALERT:
      return <AlertIcon {...iconProps} />;
    case StandardIconType.ANALYTICS:
      return <AnalyticsIcon {...iconProps} />;
    case StandardIconType.ARROW_DOWN:
      return <ArrowDownIcon {...iconProps} />;
    case StandardIconType.ARROW_LEFT:
      return <ArrowLeftIcon {...iconProps} />;
    case StandardIconType.ARROW_RIGHT:
      return <ArrowRightIcon {...iconProps} />;
    case StandardIconType.ARROW_TO_DOWN_LINE:
      return <ArrowToDownLine {...iconProps} />;
    case StandardIconType.ARROW_TO_UP_LINE:
      return <ArrowToUpLine {...iconProps} />;
    case StandardIconType.ARROW_UP:
      return <ArrowUpIcon {...iconProps} />;
    case StandardIconType.BELL_OFF:
      return <BellOffIcon {...iconProps} />;
    case StandardIconType.BELL_RING:
      return <BellRingIcon {...iconProps} />;
    case StandardIconType.BOT:
      return <BotIcon {...iconProps} />;
    case StandardIconType.CALENDAR:
      return <CalendarIcon {...iconProps} />;
    case StandardIconType.CARD:
      return <CardIcon {...iconProps} />;
    case StandardIconType.CARET_UP_FILLED:
      return <CaretUpFilledIcon {...iconProps} />;
    case StandardIconType.CHANNEL:
      return <ChannelIcon {...iconProps} />;
    case StandardIconType.CHECK:
      return <CheckIcon {...iconProps} />;
    case StandardIconType.CHEVRON_DOWN:
      return <ChevronDownIcon {...iconProps} />;
    case StandardIconType.CHEVRON_DOWN_PIXEL:
      return <ChevronDownPixelIcon {...iconProps} />;
    case StandardIconType.CHEVRON_RIGHT:
      return <ChevronRightIcon {...iconProps} />;
    case StandardIconType.CHOOSE_STATS:
      return <ChooseStatsIcon {...iconProps} />;
    case StandardIconType.CLOCK:
      return <ClockIcon {...iconProps} />;
    case StandardIconType.CODE:
      return <CodeIcon {...iconProps} />;
    case StandardIconType.COLUMNS:
      return <ColumnsIcon {...iconProps} />;
    case StandardIconType.COMPANY:
      return <CompanyIcon {...iconProps} />;
    case StandardIconType.COMPARISON:
      return <ComparisonIcon {...iconProps} />;
    case StandardIconType.CONNECT:
      return <ConnectIcon {...iconProps} />;
    case StandardIconType.CONNECTIONS:
      return <ConnectionsIcon {...iconProps} />;
    case StandardIconType.COPY:
      return <CopyIcon {...iconProps} />;
    case StandardIconType.COST:
      return <CostIcon {...iconProps} />;
    case StandardIconType.DEBUG:
      return <DebugIcon {...iconProps} />;
    case StandardIconType.DEEP_DIVE:
      return <DeepDiveIcon {...iconProps} />;
    case StandardIconType.DELIVERY:
      return <DeliveryIcon {...iconProps} />;
    case StandardIconType.DEVICE_DESKTOP_ANALYTICS:
      return <DeviceDesktopAnalyticsIcon {...iconProps} />;
    case StandardIconType.DISCONNECT:
      return <DisconnectIcon {...iconProps} />;
    case StandardIconType.DOCUMENT:
      return <DocumentIcon {...iconProps} />;
    case StandardIconType.DOT:
      return <DotIcon {...iconProps} />;
    case StandardIconType.EDIT:
      return <EditIcon {...iconProps} />;
    case StandardIconType.EMAIL:
      return <EmailIcon {...iconProps} />;
    case StandardIconType.EMPLOYEE:
      return <EmployeeIcon {...iconProps} />;
    case StandardIconType.EMPLOYEES:
      return <EmployeesIcon {...iconProps} />;
    case StandardIconType.ERROR:
      return <ErrorIcon {...iconProps} />;
    case StandardIconType.EXTERNAL_LINK:
      return <ExternalLinkIcon {...iconProps} />;
    case StandardIconType.EYE:
      return <EyeIcon {...iconProps} />;
    case StandardIconType.FEATURE_INDETERMINATE:
      return <FeatureIndeterminateIcon {...iconProps} />;
    case StandardIconType.FEATURE_OFF:
      return <FeatureOffIcon {...iconProps} />;
    case StandardIconType.FEATURE_ON:
      return <FeatureOnIcon {...iconProps} />;
    case StandardIconType.FEATURES:
      return <FeaturesIcon {...iconProps} />;
    case StandardIconType.FEEDBACK_GIVEN:
      return <FeedbackGivenIcon {...iconProps} />;
    case StandardIconType.FEEDBACK_RECEIVED:
      return <FeedbackReceivedIcon {...iconProps} />;
    case StandardIconType.FILTER:
      return <FilterIcon {...iconProps} />;
    case StandardIconType.FLAG:
      return <FlagIcon {...iconProps} />;
    case StandardIconType.FULL_SCREEN:
      return <FullScreenIcon {...iconProps} />;
    case StandardIconType.GLOBE:
      return <GlobeIcon {...iconProps} />;
    case StandardIconType.HAPPY:
      return <HappyIcon {...iconProps} />;
    case StandardIconType.HAMBURGER:
      return <HamburgerIcon {...iconProps} />;
    case StandardIconType.HEALTH:
      return <HealthIcon {...iconProps} />;
    case StandardIconType.HERO_NUMBER:
      return <HeroNumberIcon {...iconProps} />;
    case StandardIconType.HIGHLIGHTS:
      return <HighlightsIcon {...iconProps} />;
    case StandardIconType.HOME:
      return <HomeIcon {...iconProps} />;
    case StandardIconType.HOME_GRID:
      return <HomeGridIcon {...iconProps} />;
    case StandardIconType.IMPACT:
      return <ImpactIcon {...iconProps} />;
    case StandardIconType.INFO_CIRCLE:
      return <InfoCircleIcon {...iconProps} />;
    case StandardIconType.INTEGRATIONS:
      return <IntegrationsIcon {...iconProps} />;
    case StandardIconType.KEY:
      return <KeyIcon {...iconProps} />;
    case StandardIconType.KEYBOARD_DOUBLE_ARROW_RIGHT:
      return <KeyboardDoubleArrowRight {...iconProps} />;
    case StandardIconType.KEYBOARD_SINGLE_ARROW_RIGHT:
      return <KeyboardSingleArrowRight {...iconProps} />;
    case StandardIconType.LEADERBOARD:
      return <LeaderboardIcon {...iconProps} />;
    case StandardIconType.LIGHT_BULB:
      return <LightBulbIcon {...iconProps} />;
    case StandardIconType.LIGHTNING_BOLT:
      return <LightningBoltIcon {...iconProps} />;
    case StandardIconType.LINK:
      return <LinkIcon {...iconProps} />;
    case StandardIconType.LINKED_IN:
      return <LinkedInIcon {...iconProps} />;
    case StandardIconType.LOADING:
      return <LoadingIcon {...iconProps} />;
    case StandardIconType.LOCK:
      return <LockIcon {...iconProps} />;
    case StandardIconType.LOGOUT:
      return <LogoutIcon {...iconProps} />;
    case StandardIconType.MAILBOX:
      return <MailboxIcon {...iconProps} />;
    case StandardIconType.MAPPING:
      return <MappingIcon {...iconProps} />;
    case StandardIconType.MEDAL:
      return <MedalIcon {...iconProps} />;
    case StandardIconType.MEETINGS:
      return <MeetingsIcon {...iconProps} />;
    case StandardIconType.MERGE:
      return <MergeIcon {...iconProps} />;
    case StandardIconType.SAD:
      return <SadIcon {...iconProps} />;
    case StandardIconType.NEW_ACTIVITY:
      return <NewActivityIcon {...iconProps} />;
    case StandardIconType.NEW_TAB:
      return <NewTabIcon {...iconProps} />;
    case StandardIconType.NOTIFICATIONS:
      return <NotificationsIcon {...iconProps} />;
    case StandardIconType.ORG_CHART:
      return <OrgChartIcon {...iconProps} />;
    case StandardIconType.ORGS:
      return <OrgsIcon {...iconProps} />;
    case StandardIconType.PAINT_BRUSH:
      return <PaintBrushIcon {...iconProps} />;
    case StandardIconType.PANEL_LEFT_CLOSE:
      return <PanelLeftCloseIcon {...iconProps} />;
    case StandardIconType.PANEL_LEFT_OPEN:
      return <PanelLeftOpenIcon {...iconProps} />;
    case StandardIconType.PENCIL_BOLT:
      return <PencilBoltIcon {...iconProps} />;
    case StandardIconType.PERSON:
      return <PersonIcon {...iconProps} />;
    case StandardIconType.PERSON_CHECK:
      return <PersonCheckIcon {...iconProps} />;
    case StandardIconType.PERSON_MULTIPLE_CHECK:
      return <PersonMultipleCheckIcon {...iconProps} />;
    case StandardIconType.PLAY_CIRCLE:
      return <PlayCircleIcon {...iconProps} />;
    case StandardIconType.PLUS:
      return <PlusIcon {...iconProps} />;
    case StandardIconType.PLUS_CIRCLE:
      return <PlusCircleIcon {...iconProps} />;
    case StandardIconType.RECORDING:
      return <RecordingIcon {...iconProps} />;
    case StandardIconType.REFRESH:
      return <RefreshIcon {...iconProps} />;
    case StandardIconType.SAVED_VIEW:
      return <SavedViewIcon {...iconProps} />;
    case StandardIconType.SEARCH:
      return <SearchIcon {...iconProps} />;
    case StandardIconType.SEND:
      return <SendIcon {...iconProps} />;
    case StandardIconType.SETTINGS:
      return <SettingsIcon {...iconProps} />;
    case StandardIconType.SMS:
      return <SmsIcon {...iconProps} />;
    case StandardIconType.SPINNER:
      return <SpinnerIcon {...iconProps} />;
    case StandardIconType.STAR:
      return <StarIcon {...iconProps} />;
    case StandardIconType.STAR_FILLED:
      return <StarFilledIcon {...iconProps} />;
    case StandardIconType.STATS:
      return <StatsIcon {...iconProps} />;
    case StandardIconType.SUMMARY:
      return <SummaryIcon {...iconProps} />;
    case StandardIconType.SUPERPOWERS:
      return <SuperpowersIcon {...iconProps} />;
    case StandardIconType.SWITCH:
      return <SwitchIcon {...iconProps} />;
    case StandardIconType.SYNC_INTEGRATION:
      return <SyncIntegrationIcon {...iconProps} />;
    case StandardIconType.TABLE:
      return <TableIcon {...iconProps} />;
    case StandardIconType.TAG:
      return <TagIcon {...iconProps} />;
    case StandardIconType.TEAM:
      return <TeamIcon {...iconProps} />;
    case StandardIconType.TEST:
      return <TestIcon {...iconProps} />;
    case StandardIconType.TELESCOPE:
      return <TelescopeIcon {...iconProps} />;
    case StandardIconType.THREE_DOTS_VERTICAL:
      return <ThreeDotsVerticalIcon {...iconProps} />;
    case StandardIconType.THUMBS_UP:
      return <ThumbsUpIcon {...iconProps} />;
    case StandardIconType.TIME_SPENT:
      return <TimeSpentIcon {...iconProps} />;
    case StandardIconType.TRASH:
      return <TrashIcon {...iconProps} />;
    case StandardIconType.TRENDING_DOWN:
      return <TrendingDownIcon {...iconProps} />;
    case StandardIconType.TRENDING_UP:
      return <TrendingUpIcon {...iconProps} />;
    case StandardIconType.TWITTER:
      return <TwitterIcon {...iconProps} />;
    case StandardIconType.USER:
      return <UserIcon {...iconProps} />;
    case StandardIconType.USER_CIRCLE:
      return <UserCircleIcon {...iconProps} />;
    case StandardIconType.USER_PLUS:
      return <UserPlusIcon {...iconProps} />;
    case StandardIconType.WINDY:
      return <WindyStickerIcon {...iconProps} />;
    case StandardIconType.WRENCH:
      return <WrenchIcon {...iconProps} />;
    case StandardIconType.X:
      return <XIcon {...iconProps} />;
    case StandardIconType.YOUTUBE:
      return <YouTubeIcon {...iconProps} />;
    default:
      return null;
  }
};
