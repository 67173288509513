import { StandardIconType, type SerializableIcon } from "@wind/common-interfaces/icons";
import { cn } from "@wind/ui";

import { useIntegrationMeta } from "~/hooks/useAllIntegrationsMeta.js";
import { generateImageUrl } from "~/utils/generateImageUrl.js";
import { getStandardIcon } from "../standardIcons.js";

export type IconSize = "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";

const ImageInner = ({
  width,
  height,
  src,
  className,
  cropToCircle,
  fallbackIcon,
  fallbackLetter,
}: {
  width: number;
  height: number;
  className: string;
  src?: string | null;
  alt?: string;
  cropToCircle?: boolean;
  fallbackIcon?: StandardIconType;
  fallbackLetter?: string;
}) => {
  return (
    <div
      className={cn(
        "flex items-center justify-center relative",
        {
          "rounded-full overflow-hidden": cropToCircle,
          "bg-surface-muted border-[0.5px]": !src,
        },
        className
      )}
    >
      {fallbackLetter && (
        <div
          className="flex items-center justify-center absolute inset-0 text-tertiary font-medium"
          style={{
            fontSize: `${width * 0.5}px`,
          }}
        >
          {fallbackLetter}
        </div>
      )}
      {fallbackIcon &&
        !fallbackLetter &&
        getStandardIcon(fallbackIcon, { className: "size-[65%] z-0" })}
      {src && (
        <div
          className={cn("absolute inset-0 bg-cover bg-center z-0", {
            "rounded-full overflow-hidden": cropToCircle,
          })}
          style={{
            backgroundImage: `url("${generateImageUrl(src, {
              width: width * 3,
              height: height * 3,
            })}")`,
          }}
        />
      )}
    </div>
  );
};

const IntegrationIconInner = ({
  width,
  height,
  className,
  integrationId,
}: {
  width: number;
  height: number;
  className: string;
  integrationId: string;
}) => {
  const meta = useIntegrationMeta(integrationId);

  return (
    <ImageInner
      src={meta.logoUrl}
      width={width}
      height={height}
      className={className}
      alt={integrationId}
    />
  );
};

const SIZE_PIXELS: Record<IconSize, number> = {
  sm: 18,
  md: 30,
  lg: 36,
  xl: 44,
  "2xl": 60,
  "3xl": 80,
};

const CONTAINER_CLASSNAME: Record<IconSize, string> = {
  sm: "size-[1.125rem]",
  md: "size-[1.5rem]",
  lg: "size-[2rem]",
  xl: "size-[2.75rem]",
  "2xl": "size-[3.75rem]",
  "3xl": "size-[5.5rem]",
};

interface Props {
  size: IconSize;
  icon?: SerializableIcon;
  isLoading?: boolean;
  className?: string;
  rounded?: boolean;
}

const Icon = ({ size, icon, isLoading, className, rounded }: Props) => {
  const sizePixels = SIZE_PIXELS[size];

  return (
    <div
      className={cn(
        "flex items-center justify-center flex-none",
        CONTAINER_CLASSNAME[size],
        {
          "bg-surface-muted rounded-full": !isLoading && icon && "icon" in icon && icon.isBadge,
          "bg-surface-muted rounded-full animate-pulse": isLoading,
        },
        className
      )}
    >
      {!isLoading &&
        icon &&
        "icon" in icon &&
        icon.isBadge &&
        getStandardIcon(icon.icon, { className: "size-[65%]" })}
      {!isLoading &&
        icon &&
        "icon" in icon &&
        !icon.isBadge &&
        getStandardIcon(icon.icon, { className: "w-full h-full" })}

      {!isLoading && icon && "imageUrl" in icon && (
        <ImageInner
          src={icon.imageUrl}
          width={sizePixels}
          height={sizePixels}
          className="w-full h-full"
          cropToCircle
          fallbackIcon={icon.fallbackIcon}
        />
      )}
      {!isLoading && icon && "employee" in icon && (
        <ImageInner
          src={icon.employee.avatarUrl}
          width={sizePixels}
          height={sizePixels}
          className="w-full h-full"
          cropToCircle
          fallbackLetter={icon.employee.name.charAt(0) || icon.employee.primaryEmail?.charAt(0)}
          fallbackIcon={StandardIconType.PERSON}
        />
      )}
      {!isLoading && icon && "color" in icon && (
        <div
          className="w-full h-full rounded-full bg-transparent"
          style={{
            boxShadow: `inset 0 0 0 ${sizePixels / 5}px ${icon.color}`,
          }}
        />
      )}
      {!isLoading && icon && "integrationId" in icon && (
        <IntegrationIconInner
          integrationId={icon.integrationId}
          width={sizePixels}
          height={sizePixels}
          className={cn("w-full h-full overflow-hidden rounded-md", {
            "rounded-full": rounded,
          })}
        />
      )}
    </div>
  );
};

export default Icon;
